export default (context, inject) => {

  // add axios interceptors
  context.$axios.interceptors.request.use((config) => {
    const token = context.store.getters['authStorage/accessToken']
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  })

  // redirect to login page if 401
  context.$axios.onResponseError(e => {
    if ((e.response && e.response?.status === 422) && context.store.getters['authStorage/isOrganisationActiveAuthenticated']) {
      context.store.dispatch('snackbar/errorResponse', e)
    } else if (e.response && e.response?.status === 401 && !context.store.getters['authStorage/isOrganisationActiveAuthenticated']) {
      console.log('Redirecting to /switch due to 401 error');
      /*context.redirect({
        name: 'switch'
      })
        */
    }
  })

  // inject $auth
  inject('auth', {
    get user() {
      return context.store.getters['authUserChallenge/getUser']
    },
    get userId() {
      return context.store.getters['authUserChallenge/getUser']?.id || false
    },
    get userIsAdmin() {
      return context.store.getters['authUserChallenge/getUser']?.is_admin || false
    },
    get userProfileImage() {
      return context.store.getters['authUserChallenge/getUser']?.profileImage?.proxy || false
    },
    get userFullName() {
      let firstname = context.store.getters['authUserChallenge/getUser']?.firstname || ''
      let lastname = context.store.getters['authUserChallenge/getUser']?.lastname || ''
      return `${firstname} ${lastname}`
    },
    get activeOrganisation() {
      return context.store.getters['authStorage/getActiveOrganisation']
    },
    get activeOrganisationLanguages() {
      return context.store.getters['authStorage/getActiveOrganisationLanguages']
    },
    get activeOrganisationColor() {
      return context.store.getters['authStorage/organisationColor']
    },
    get wssToken() {
      return context.store.getters['authStorage/wssToken']
    }
  })
}
