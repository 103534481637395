/*
 * ######
 * Auth User Challenge Represents the "USER CHALLENGE" state.
 * This means you can be loggedin with a chellenge for a user, but not yet authenticated for an organisation.
 * So here is also everything related to the user, which has nothing to do with the organisation.
 * ######
 */
export const state = () => ({
  storageVersion: 1,
  _challengeToken: null,
  _user: null,
  _darkMode: false,
})

/*
$store.getters['authUserChallenge/getChallengeToken']
$store.getters['authUserChallenge/getIsChallengeTokenActive']
$store.getters['authUserChallenge/getUser']
$store.getters['authUserChallenge/getUserAppLanguage']
$store.getters['authUserChallenge/getDarkMode']
*/
export const getters = {
  getChallengeToken(state) {
    return state._challengeToken || false
  },
  getIsChallengeTokenActive(state) {
    return !!state._challengeToken;
  },
  getUser(state) {
    return state._user || false
  },
  getUserAppLanguage(state) {
    return state._user.hasOwnProperty('app_language') ? state._user.app_language : 'de'
  },
  getDarkMode(state) {
    return state._darkMode
  }
}

/*
$store.commit('authUserChallenge/setChallengeToken', token)
$store.commit('authUserChallenge/setUser', user)
$store.commit('authUserChallenge/destroy')
$store.commit('authUserChallenge/setDarkMode', darkMode)
*/
export const mutations = {
  setChallengeToken(state, challengeToken) {
    state._challengeToken = challengeToken
  },
  setUser(state, user) {
    state._user = user
  },
  destroy(state) {
    state._challengeToken = null
    state._user = null
  },
  setDarkMode(state, darkMode) {
    state._darkMode = darkMode
  }
}

/*
const user = await this.$store.dispatch('authUserChallenge/getUser')
const user = await this.$store.dispatch('authUserChallenge/getAndSetUser')
const organisations = await this.$store.dispatch('authUserChallenge/getOrganisations')
const response = await this.$store.dispatch('authUserChallenge/getOrganisationToken', organisationId)
const response = await this.$store.dispatch('authUserChallenge/destroyChallengeToken')
*/
export const actions = {
  getUser(context) {
    return this.$axios.$post('challenge-auth/me', {
      challenge: context.getters.getChallengeToken,
    });
  },
  async getAndSetUser(context) {
    const response = await context.dispatch('getUser');
    context.commit('setUser', response.user);
    return response;
  },
  getOrganisations(context) {
    return this.$axios.$post('challenge-auth/organisations', {
      challenge: context.getters.getChallengeToken,
    });
  },
  getOrganisationToken(context, organisationId) {
    return this.$axios.$post('challenge-auth/organisations/' + organisationId + '/token', {
      challenge: context.getters.getChallengeToken,
      useragent: window.navigator.userAgent || ''
    });
  },
  destroyChallengeToken(context) {
    return this.$axios.$post('challenge-auth/destroy', {
      challenge: context.getters.getChallengeToken
    });
  },
}
