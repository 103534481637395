
import VSnackbars from "v-snackbars"
import Queue from '../components/layouts/Queue.vue'
import EntitySuccessQueue from '../components/layouts/EntitySuccessQueue.vue'
import TermsOverlay from '../components/layouts/TermsOverlay.vue'
import WelcomeHelpOverlay from '../components/layouts/WelcomeHelpOverlay.vue'
import NewsletterOptInOverlay from '../components/layouts/NewsletterOptInOverlay.vue'
import ActiveSelection from '../components/ActiveSelection.vue'
import AddMenu from '../components/layouts/AddMenu.vue'
import DenseContainer from '../components/screens/DenseContainer.vue'

export default {
  components: {
    Drawer: () => import('@/components/layouts/Drawer'),
    AppBar: () => import('@/components/layouts/AppBar'),
    Help: () => import('@/components/layouts/Help'),
    "v-snackbars": VSnackbars,
    Queue,
    EntitySuccessQueue,
    TermsOverlay,
    WelcomeHelpOverlay,
    NewsletterOptInOverlay,
    ActiveSelection,
    AddMenu,
    DenseContainer
  },
  middleware: ['organisation'],
  data: () => ({
    helpDrawer: false,
    isLoading: true,
    interval: 0
  }),
  async fetch() {
    try {
      await this.$store.dispatch('authStorage/ensureActiveOrganisation')
      await this.$store.dispatch('me/fetch')
      // after fetching the user, set the application language
      this.$nuxt.$emit('changeAppLanguage', this.$store.getters['authUserChallenge/getUserAppLanguage'])
      await this.$store.dispatch('me/menu')
      await this.$store.commit('layout/drawer', !this.$vuetify.breakpoint.mdAndDown)
      await this.$store.dispatch('hub/websocketUsersConnet', {orgId: this.$auth.activeOrganisation.id, server: this.$nuxt.context.env.socketServer})
      this.isLoading = false
    } catch (error) {
      //this.$sentry.captureException(error)
    }

  },
  head() {
    return {
      title: this.lastBreadcrumb
    }
  },
  computed: {
    lastBreadcrumb() {
      const last = this.$store.state.hub.breadcrumbData[this.$store.state.hub.breadcrumbData.length - 1]
      return last ? last.text : ''
    },
    snackbar: {
      get() {
        return this.$store.state.snackbar.items;
      },
      set(value) {
        this.$store.commit('snackbar/cleanup', value)
      }
    }
  },
  created() {
    this.$nuxt.$on('changeAppLanguage', (value) => {
      this.$axios.setHeader('Accept-Language', value)
      if (value !== this.$vuetify.lang.current) {
        this.$vuetify.lang.current = value
      }
    })
    this.$store.commit('snackbar/cleanup', [])

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('online', this.checkConnectionAndReconnect);
    window.addEventListener('offline', this.updateOfflineStatus);
    window.addEventListener('keyup', () => this.$store.commit('hub/setActivity'))

    this.interval = setInterval(() => {
      this.$store.dispatch('hub/sendStaleActivity')
    }, 60000);

    this.$sentry?.setUser({
      id: this.$auth.user?.id,
      email: this.$auth.user?.email
    })
  },
  mounted() {
    this.$vuetify.theme.dark = this.$store.getters['authUserChallenge/getDarkMode']
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$store.dispatch('hub/websocketUsersClose')
    window.removeEventListener('online', this.checkConnectionAndReconnect);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('offline', this.updateOfflineStatus);
  },
  methods: {
    updateOfflineStatus() {
      if (this.$store.getters['hub/isWebsocketConnected']) {
        this.$store.dispatch('hub/websocketUsersClose')
      }
    },
    checkConnectionAndReconnect() {
      if (navigator.onLine && !this.$store.getters['hub/isWebsocketConnected']) {
        this.$store.dispatch('hub/websocketUsersConnet', {orgId: this.$store.getters['authStorage/getActiveOrganisationId'], server: this.$nuxt.context.env.socketServer})
      }
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.checkConnectionAndReconnect();
      }
    },
    toggleHelpDrawerVisibility() {
      this.helpDrawer = !this.helpDrawer
    },
  }
}
