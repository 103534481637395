/**
 * {{ $organisation.scalarTranslation(poi.teaser_description) }}
 */
export default (context, inject) => {
  // inject $organisation
  inject('organisation', {

    avatarify(value, second) {
      if (!value) {
        return value;
      }
      const words = value.split(/[\s,-]+/);

      if (words.length == 2) {
        return words[0].substr(0, 1).toUpperCase() + words[1].substr(0, 1).toUpperCase()
      }

      if (second && second.length > 0) {
        return value.substr(0, 1).toUpperCase() + second.substr(0, 1).toUpperCase()
      }

      if (words.length > 1) {
        return words[0].substr(0, 1).toUpperCase() + words[1].substr(0, 1).toUpperCase()
      }

      return value.substr(0, 2).toUpperCase()
    },

    formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) {
        return '0 Bytes';
      }
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const formattedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
      return `${formattedSize} ${sizes[i]}`;
    },

    scalarTranslation(value) {
      if (!value) {
        return;
      }

      if (!_.isObject(value)) {
        value = JSON.parse(value);
      }

      const languages = context.store.getters['authStorage/getActiveOrganisationLanguages']

      let result;
      languages.forEach(lang => {
        if (!result && value.hasOwnProperty(lang.shortcode)) {
          result = value[lang.shortcode]
        }
      });

      return result;
    }
  })
}
