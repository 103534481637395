
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 400,
    },
  },
  methods: {
    toggleDarkMode() {
      const newDarkModeState = !this.$vuetify.theme.dark
      this.$store.commit('authUserChallenge/setDarkMode', newDarkModeState)
      this.$vuetify.theme.dark = newDarkModeState
    }
  }
};
