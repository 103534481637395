export default async function (context) {
  // there is no active organisation
  if (!context.store.getters['authStorage/isOrganisationActiveAuthenticated']) {

    context.store.commit('login/setRedirectPath', context.route.fullPath)

    // if there is an active user session, redirect to switch page
    if (context.store.getters['authUserChallenge/getIsChallengeTokenActive']) {
      return context.redirect({
        name: 'switch'
      })
    }

    // there is no active user session, redirect to login page
    return context.redirect({
      name: 'auth-login'
    })
  }
}
