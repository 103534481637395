export const state = () => ({
  _redirectPath: false,
  _temporaryUnfulfilledChallenge: false,
  _temporaryUnfulfilledChallengeValidationType: false, // 'otp', 'emailcode'
})

/*
$store.getters['login/getEnsuredRedirectPath']
$store.getters['login/getTemporaryUnfulfilledChallenge']
$store.getters['login/getTemporaryUnfulfilledChallengeValidationType']
*/
export const getters = {
  getEnsuredRedirectPath: state => state._redirectPath && state._redirectPath.startsWith('/') ? state._redirectPath : false,
  getTemporaryUnfulfilledChallenge: state => state._temporaryUnfulfilledChallenge || false,
  getTemporaryUnfulfilledChallengeValidationType: state => {
    const type = state._temporaryUnfulfilledChallengeValidationType;
    return type === 'otp' || type === 'emailcode' ? type : false;
  }
}

/*
$store.commit('login/setRedirectPath', value)
$store.commit('login/clearRedirectPath')
$store.commit('login/setTemporaryUnfulfilledChallenge', challenge)
$store.commit('login/setTemporaryUnfulfilledChallengeValidationType', type)
$store.commit('login/destroyTemporaryUnfulfilledChallenge')
*/
export const mutations = {
  setRedirectPath(state, value) {
    state._redirectPath = value
  },
  clearRedirectPath(state) {
    state._redirectPath = false
  },
  setTemporaryUnfulfilledChallenge(state, challenge) {
    state._temporaryUnfulfilledChallenge = challenge
  },
  setTemporaryUnfulfilledChallengeValidationType(state, type) {
    state._temporaryUnfulfilledChallengeValidationType = type.toLowerCase()
  },
  destroyTemporaryUnfulfilledChallenge(state) {
    state._temporaryUnfulfilledChallenge = false
    state._temporaryUnfulfilledChallengeValidationType = false
  }
}
