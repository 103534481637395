export const state = () => ({
  _title: '',
})

/*
$store.getters['containerLayout/getTitle']
*/
export const getters = {
  getTitle: state => state._title,
}

/*
$store.commit('containerLayout/setTitle', title)
$store.commit('containerLayout/clearTitle')
*/
export const mutations = {
  setTitle(state, value) {
    state._title = value
  },
  clearTitle(state) {
    state._title = ''
  }
}
