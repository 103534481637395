import { uuidv4 } from "../helpers/forms";

/*
 * ######
 * Auth Storage Represents the "ORGANISATION AUTHENTICATE" state.
 * This means you are activeley opted in to an organisation and are authenticated with this "user token".
 * The name is history, but it should be authOrganisation...
 * ######
 */
export const state = () => ({
  storageVersion: 2,
  _activeOrganisationId: null,
  _activeOrganisation: null,
  _newInjectedToken: false,
  _newInjectedWssToken: false,
})

/*
$store.getters['authStorage/accessToken']
$store.getters['authStorage/wssToken']
$store.getters['authStorage/isOrganisationActiveAuthenticated']
$store.getters['authStorage/getActiveOrganisation']
$store.getters['authStorage/getActiveOrganisationLanguages']
$store.getters['authStorage/getActiveOrganisationId']
$store.getters['authStorage/firstOrganisationLanguage']
$store.getters['authStorage/organisationColor']
*/
export const getters = {
  accessToken(state) {  // read only wird gebraucht in auth.js
    return state._newInjectedToken || false;
  },
  wssToken(state) { // wird gebraucht in auth.js
    return state._newInjectedWssToken || false;
  },
  isOrganisationActiveAuthenticated(state) {
    return (state._newInjectedToken || false) &&
      (state._newInjectedWssToken || false) &&
      (state._activeOrganisation || false);
  },
  getActiveOrganisation(state) {
    return state._activeOrganisation;
  },
  getActiveOrganisationLanguages(state) {
    return state._activeOrganisation.languages || []
  },
  getActiveOrganisationId(state) {
    return state._activeOrganisationId;
  },
  firstOrganisationLanguage(state) {
    return state._activeOrganisation.languages[0];
  },
  organisationColor(state) {
    const hex8 = state._activeOrganisation.color || '#333'
    if (hex8.length !== 9 || hex8[0] !== '#') {
      return hex8
    }
    return hex8.substring(0, 7);
  },
}

export const mutations = {
  setNewInjectedToken(context, data) {
    context._newInjectedToken = data
  },
  setNewInjectedWssToken(context, data) {
    context._newInjectedWssToken = data
  },
  setActiveOrganisationId(state, organisationId) {
    state._activeOrganisationId = organisationId
  },
  setActiveOrganisation(state, organisation) {
    state._activeOrganisation = organisation
  },
  destroy(state) {
    state._activeOrganisationId = null
    state._activeOrganisation = null
    state._newInjectedToken = false
    state._newInjectedWssToken = false
  },
}

export const actions = {

  async loginOrganisation(context, { orgId, accessToken, wssToken }) {
    await context.commit('setNewInjectedToken', accessToken);
    await context.commit('setNewInjectedWssToken', wssToken);
    await context.commit('setActiveOrganisationId', orgId);

    const checkToken = () => {
      return new Promise((resolve, reject) => {
        const maxAttempts = 10;
        let attempts = 0;

        const interval = setInterval(() => {
          if (context.getters.accessToken === accessToken) {
            clearInterval(interval);
            resolve(); // Token matches
          } else if (attempts >= maxAttempts) {
            clearInterval(interval);
            reject(new Error('Token check failed: max attempts reached'));
          }
          attempts++;
        }, 200);
      });
    };

    try {
      await checkToken();
      await context.dispatch('ensureActiveOrganisation');
    } catch (error) {
      console.error(error.message);
      // Handle error case, e.g., notify the user or retry the whole operation
    }
  },


  // this loads the current organisation based on the current access token
  // the token is inject in the axios auth.js plugin
  async ensureActiveOrganisation(context) {
    try {
      const response = await this.$axios.get('organisation', {
        headers: {
          Authorization: `Bearer ${context.state._newInjectedToken}`,
          Challenge: uuidv4()
        }
      })
      await context.commit('setActiveOrganisation', response.data)
      let timezone = "Europe/Zurich" // testing: America/New_York
      if (response.data.timezone && response.data.timezone.length > 0) {
        timezone = response.data.timezone
      }
      this.$moment.tz.setDefault(timezone);
    } catch (error) {
      await context.commit('destroy')
    }
  },
}
