const middleware = {}

middleware['challenge'] = require('../middleware/challenge.js')
middleware['challenge'] = middleware['challenge'].default || middleware['challenge']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['organisation'] = require('../middleware/organisation.js')
middleware['organisation'] = middleware['organisation'].default || middleware['organisation']

export default middleware
