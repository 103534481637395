
import DenseContainer from '../components/screens/DenseContainer.vue'
export default {
  components: { DenseContainer },
  fetch() {
    this.$vuetify.theme.dark = this.$store.getters['authUserChallenge/getDarkMode']
  },
  computed: {
    title() {
      return this.$store.getters['containerLayout/getTitle']
    }
  },
}
